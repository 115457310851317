

export default function componentStyleOverrides(theme) {
    const bgColor = theme?.customization?.mode === 'dark' ? theme.colors?.darkBackground : theme.colors?.grey50;
    return {
        link: {
            textDecoration: "none"
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    textTransform: 'capitalize',
                    borderRadius: '19px',
                    paddingLeft:'30px',
                    paddingRight:'30px'


                },
                contained: {
                    color: 'white'
                }
            }

        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiDrawer: {
            defaultProps: {
              

            },
            styleOverrides: {
            }
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                }, paper: {
                    boxShadow: '0px 2px 4px 0px grey'
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '20px!important'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                },
                outlined: {
                    border: '1px dashed'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        color: theme.menuSelected,
                        backgroundColor: theme.menuSelectedBack,
                        '&:hover': {
                            backgroundColor: theme.menuSelectedBack
                        },
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    },
                    '&:hover': {
                        backgroundColor: theme.menuSelectedBack,
                        color: theme.menuSelected,
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: theme?.customization?.outlinedFilled ? bgColor : 'transparent',
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme?.customization?.mode === 'dark' ? theme.colors?.darkTextPrimary + 28 : theme.colors?.grey400
                    },
                    '&:hover $notchedOutline': {
                        borderColor: theme.colors?.primaryLight
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    }
                },
                input: {
                    fontWeight: 500,
                    background: theme?.customization?.outlinedFilled ? bgColor : 'transparent',
                    padding: '15.5px 14px',
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme?.customization?.mode === 'dark' ? theme.colors?.darkTextPrimary + 50 : theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.customization?.mode === 'dark' ? theme?.colors?.primaryMain : theme?.colors?.primaryLight
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-tag': {
                        background:
                            theme?.customization?.mode === 'dark' ? theme.colors?.darkTextPrimary + 20 : theme.colors?.secondaryLight,
                        borderRadius: 4,
                        color: theme.textDark,
                        '.MuiChip-deleteIcon': {
                            color:
                                theme?.customization?.mode === 'dark' ? theme.colors?.darkTextPrimary + 80 : theme.colors?.secondary200
                        }
                    }
                },
                popper: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: theme?.customization?.mode === 'dark' ? 0.2 : 1
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        },

        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme?.customization?.mode === 'dark' ? theme.colors?.darkLevel1 : theme.colors?.primaryDark,
                    background: theme?.customization?.mode === 'dark' ? theme.darkTextPrimary : theme.colors?.primary200
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    color: theme.textDark,
                    fontSize: '16px'
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    marginTop: 14,
                    marginBottom: 14
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiInternalDateTimePickerTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor: theme?.customization?.mode === 'dark' ? theme.colors?.darkPaper : theme.colors?.primaryLight,
                    '& .MuiTabs-flexContainer': {
                        borderColor:
                            theme?.customization?.mode === 'dark' ? theme?.colors?.darkTextPrimary + 20 : theme.colors?.primary200
                    },
                    '& .MuiTab-root': {
                        color: theme?.customization?.mode === 'dark' ? theme.colors?.darkTextSecondary : theme.colors?.grey900
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.colors?.primaryDark
                    },
                    '& .Mui-selected': {
                        color: theme.colors?.primaryDark
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    borderBottom: '1px solid',
                    borderColor: theme?.customization?.mode === 'dark' ? theme.colors?.darkTextPrimary + 20 : theme.colors?.grey200
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: '10px',
                    border: '5px solid '+theme.colors?.successDark,
                    minHeight: '300px',
                    maxWidth:'850px'

                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: theme?.customization?.mode === 'dark' ? theme.colors?.darkTextPrimary + 15 : theme.colors?.grey200,
                    '&.MuiTableCell-head': {
                        fontSize: '0.875rem',
                        color: theme.heading,
                        fontWeight: 500,
                        backgroundColor: theme.colors?.primaryLight
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.colors?.primaryLight,
                    background:theme?.colors?.primaryMain,
                    fontSize: "1em"
                },
                arrow: {
                    color: theme?.colors?.primaryMain

                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem'
                }
            }
        }
    };
}
