import React, { useState, useEffect, useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ReactPlayer from 'react-player/lazy';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    MenuItem,
    IconButton,
    Dialog,
    DialogContent,
    Pagination,
    DialogTitle,
    Typography,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Menu,
    Divider,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Loader from '../../../ui-elements/loader';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';

const Posts = ({ ...props }) => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { classes } = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [isCommentOpen, setIsCommentOpen] = useState(false);
    const [comments, setComments] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const commentsContainerRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedComment, setSelectedComment] = useState(null);


    const functions = getFunctions();
    const [loading, setLoading] = useState(false);

    const pageSize = 10; // Number of posts per page
    const [pageTokens, setPageTokens] = useState([null]);

    useEffect(() => {
        const container = commentsContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [commentsContainerRef]);

    const handleScroll = () => {
        const container = commentsContainerRef.current;
        if (container.scrollHeight - container.scrollTop === container.clientHeight && hasMore) {
            if (comments.length > pageSize) {
                console.log('setHasMore')
                setHasMore(true);
            } else {
                setHasMore(false);
            }

            fetchMoreComments();
        }
    };

    const handleMenuOpen = (event, comment) => {
        setAnchorEl(event.currentTarget);
        setSelectedComment(comment);

    };

    const handleMenuClose = () => {

        setAnchorEl(null);
        setSelectedComment(null);
    };
    const fetchPosts = async (page) => {
        try {
            const callableReturnMessage = httpsCallable(functions, 'getPaginatedPosts');
            const pageToken = pageTokens[page - 1];
            const response = await callableReturnMessage({ pageSize, pageToken });
            setPosts(response.data.posts);
            setTotalPages(Math.ceil(response.data.total / pageSize));
            // Update pageTokens state to keep track of page tokens for each page
            setPageTokens((prevTokens) => {
                const updatedTokens = [...prevTokens];
                updatedTokens[page] = response.data.nextPageToken;
                return updatedTokens;
            });
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    useEffect(() => {
        setLoading(true);

        fetchPosts(1);
    }, []);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
        fetchPosts(page);
    };

    const handleOpenPopup = (post) => {

        setSelectedPost(post); 
        setIsOpen(true);

    };

    const handleClosePopup = () => {
        setIsOpen(false);
        setIsCommentOpen(false);
        setIsOpenMenu(false);
    };
    const getStatusClass = (status) => {
        switch (status) {
            case 'disabled':
                return classes.disabled;
            case 'active':
                return classes.active;
            case 'completed':
                return classes.active;
            case 'deleted':
                return classes.deleted;
            case 'reported':
                return classes.reported;
            default:
                return '';
        }
    };
    const handleClick = (event, post) => {
        setSelectedPost(post);

        setIsOpenMenu(true);
    };

    const handleClose = () => {
        setSelectedPost(null);
    };
    const handleStatusChange = (selectedPost, status) => {
        selectedPost.status = status;

        const callableReturnMessage = httpsCallable(functions, 'changePostStatus');

        callableReturnMessage({ postId: selectedPost.postId, status: status }).then((response) => {
        }).catch((error) => {
            console.log(error);
        });
    }
    const handleStatusMenuItemClick = (status) => {
        handleStatusChange(selectedPost, status);
        handleClose();
    };

    const fetchComments = async (postId, startAfter) => {
        try {
            const callableGetComments = httpsCallable(functions, 'getAdminCommentsByPost');
            const response = await callableGetComments({ postId, limit: pageSize, startAfter });
            const newComments = response.data.comments || [];
            console.log('newComments', response);
            setComments((prevComments) => [...prevComments, ...newComments]);
            if (comments.length > pageSize) {
                console.log('setHasMore')
                setHasMore(true);
            } else {
                setHasMore(false);
            }

        } catch (error) {
            console.log(error);
            setHasMore(false);

        }
    };

    const fetchMoreComments = () => {
        const lastComment = comments[comments.length - 1];
        fetchComments(selectedPost, lastComment);
    };
    const handleOpenCommentPopup = async (postId) => {
        setIsCommentOpen(true);
        setSelectedPost(postId);
        setComments([]);
        fetchComments(postId, null);
    };
    const handleCommentStatusChange = (comment, status) => {

        const callableReturnMessage = httpsCallable(functions, 'changeCommentStatusByAdmin');

        callableReturnMessage({ commentId: comment.commentId, status: status }).then((response) => {
        }).catch((error) => {
            console.log(error);
        });
    }
    const handleEditStatus = (comment, status) => {
        comment.status = status;
        handleCommentStatusChange(comment, status)
        handleMenuClose();
    }
    return (
        <div>
            {loading ? <Loader /> : <div>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Media Type</TableCell>
                                <TableCell>Media</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>User Type</TableCell>

                                <TableCell>Status</TableCell>
                                <TableCell> </TableCell>
                                <TableCell> </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {posts.map((post) => (
                                <TableRow key={post.postId}>
                                    <TableCell>{post.title}</TableCell>
                                    <TableCell>{post.description}</TableCell>
                                    <TableCell>{post.postType === 'i' ? 'Image' : 'Video'}</TableCell>
                                    <TableCell>{post.postType === 'i' ? (
                                        <Zoom>
                                            {post.mediaUrl && <img src={post.mediaUrl} width={30} height={30} alt="mediaUrl" style={{ cursor: 'zoom-in', borderRadius: '50%', marginTop: '10px' }} />}
                                        </Zoom>
                                    ) : (
                                        <div>
                                            <div>
                                                {post.thumbnail && (
                                                    <>
                                                        <img
                                                            src={post.thumbnail}
                                                            width={30}
                                                            height={30}
                                                            alt="mediaUrl"
                                                            style={{
                                                                cursor: 'zoom-in',
                                                                borderRadius: '50%',
                                                                marginTop: '10px',
                                                            }}
                                                            onClick={() => handleOpenPopup(post)}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                            <Dialog BackdropProps={{ style: { backgroundColor: 'transparent' }, }} open={isOpen} onClose={handleClosePopup}>
                                                <DialogContent>
                                                    <Typography align='center' color="primary" variant="h2">Post title : {selectedPost?.title}.</Typography>
                                                    <Divider style={{ margin: 10 }} />
                                                    {selectedPost?.mediaUrl && (
                                                        <ReactPlayer
                                                            className='react-player'
                                                            url={selectedPost?.mediaUrl}
                                                            pip={false}
                                                            playing={false}
                                                            controls={true}
                                                            light={selectedPost.thumbnail ? selectedPost.thumbnail : ""}
                                                            loop={false}
                                                        />
                                                    )}
                                                </DialogContent>
                                            </Dialog>
                                        </div>
                                    )}</TableCell>
                                    <TableCell>
                                        <Zoom>
                                            {post.avatarUrl && (
                                                <img src={post.avatarUrl} width={30} height={30} alt="user" style={{ cursor: 'zoom-in', borderRadius: '50%', marginTop: '10px' }} />
                                            )}
                                        </Zoom>
                                        {post.displayName}
                                    </TableCell>
                                    <TableCell>{post.userType === "main" ? "Person" : "Pet"}</TableCell>

                                    <TableCell className={getStatusClass(post.status)}>
                                        {post.status.charAt(0).toUpperCase() + post.status.slice(1)} </TableCell>
                                    <TableCell>    <IconButton
                                        variant="outlined"
                                        color='primary'
                                        onClick={() => handleOpenCommentPopup(post.postId)}
                                    >
                                        <ChatIcon />
                                    </IconButton></TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e, post)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Popup for changing status */}
                <Dialog BackdropProps={{ style: { backgroundColor: 'rgba(128, 128, 128, 0.5)' } }} open={isOpenMenu} onClose={handleClosePopup}>
                    <DialogTitle>Change Post Status</DialogTitle>
                    <DialogContent>
                        <MenuItem className={classes.barMenuItem} onClick={() => handleStatusMenuItemClick('completed')}>Completed</MenuItem>
                        <MenuItem className={classes.barMenuItem} onClick={() => handleStatusMenuItemClick('reported')}>Reported</MenuItem>
                        <MenuItem className={classes.barMenuItem} onClick={() => handleStatusMenuItemClick('deleted')}>Deleted</MenuItem>
                    </DialogContent>
                </Dialog>
                <div className="pagination">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined" shape="rounded"
                        color="primary" />
                </div>
                <Dialog open={isCommentOpen} onClose={handleClosePopup}>
                    <DialogContent style={{ minWidth: '300px', overflowX: 'auto' }}>
                        <Typography variant="h5" align='center'>List of comments :</Typography>
                        <List ref={commentsContainerRef} style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            {comments.map((comment) => (
                                <ListItem key={comment.createdAt}>
                                    <ListItemAvatar>
                                        <Avatar>{comment.avatarUrl}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={comment.comment} secondary={` ${comment.displayName}`} />
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="body2" className={getStatusClass(comment.status)}>
                                            {comment.status.charAt(0).toUpperCase() + comment.status.slice(1)}
                                        </Typography>
                                        <IconButton
                                            aria-label="Edit status"
                                            onClick={(event) => handleMenuOpen(event, comment)}
                                            size="small"
                                            color='primary'
                                            style={{ marginLeft: '20px' }}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                    <Menu
                                        id="status-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl && selectedComment && selectedComment.id === comment.id)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => handleEditStatus(comment, 'active')}>Active</MenuItem>
                                        <MenuItem onClick={() => handleEditStatus(comment, 'reported')}>Reported</MenuItem>
                                        <MenuItem onClick={() => handleEditStatus(comment, 'deleted')}>Deleted</MenuItem>
                                    </Menu>
                                </ListItem>
                            ))}
                            {hasMore && (
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                    <CircularProgress />
                                </div>
                            )}    {comments.length == 0 && !hasMore && (
                                <Typography>No content yet.</Typography>
                            )}
                        </List>
                    </DialogContent>
                </Dialog>

            </div>}
        </div>
    );
};

const useStyles = makeStyles({ "name": { Posts } })(theme => ({
    formInput: {
        ...theme.typography.customInput,
        marginBottom: '10px !important'
    },
    avatar: {
        margin: 'auto',
        width: '100px!important',
        height: '100px!important'
    },
    dialogHeader: {
        paddingBottom: '50px!important'
    },
    disabled: {
        color: theme.palette.error.main, // Customize the color for disabled status
    },
    active: {
        color: theme.palette.primary.main, // Customize the color for active status
    },
    completed: {
        color: theme.palette.primary.main, // Customize the color for active status
    },
    reported: {
        color: theme.palette.warning.main, // Customize the color for deleted status
    },
    deleted: {
        color: theme.palette.warning.main, // Customize the color for deleted status
    }, barMenuItem: {
        width: '200px',
        textAlign: 'center',
        padding: '20px!important',
        "&:hover": {
            background: theme.palette.success.main
        }
    },
}));

export default Posts;
