
import { makeStyles } from 'tss-react/mui';

import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Pagination,
    IconButton,
    MenuItem,
    Dialog,
    DialogTitle
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Loader from '../../../ui-elements/loader';
const Pets = ({ ...props }) => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { classes } = useStyles();
    const [pageTokens, setPageTokens] = useState([null]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [loading, setLoading] = useState(false);

    const pageSize = 10;
    const functions = getFunctions();


    const handleClosePopup = () => {
        setIsOpenMenu(false);
    };
    const fetchUsers = async (page) => {
        try {
            const callableReturnMessage = httpsCallable(functions, 'getPaginatedPets');
            const pageToken = pageTokens[page - 1]; // Adjust index to match page number
            const response = await callableReturnMessage({ pageSize, pageToken });
            setUsers(response.data.users);
            setTotalPages(Math.ceil(response.data.totalUsers / pageSize));
            // Update pageTokens state to keep track of page tokens for each page
            setPageTokens((prevTokens) => {
                const updatedTokens = [...prevTokens];
                updatedTokens[page] = response.data.nextPageToken;
                return updatedTokens;
            });
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };


    useEffect(() => {
        setLoading(true);
        fetchUsers(1);
    }, []);

   
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
        fetchUsers(page);
    };
    const getStatusClass = (status) => {
        switch (status) {
            case 'disabled':
                return classes.disabled;
            case 'active':
                return classes.active;
            case 'deleted':
                return classes.deleted;
            case 'reported':
                return classes.reported;
            default:
                return '';
        }
    };
    const handleClick = (event, user) => {
        setSelectedUser(user);
        console.log('user',user)
        setIsOpenMenu(true);

    };
    const handleClose = () => {
        setSelectedUser(null);
        setIsOpenMenu(false);

    };
    const handleStatusChange = (status) => {
        selectedUser.status = status;

        const callableReturnMessage = httpsCallable(functions, 'changePetStatus');

        callableReturnMessage({ petId: selectedUser.petId, status: status }).then((response) => {

        }).catch((error) => {
            console.log(error);
        });
    }
    const handleStatusMenuItemClick = (status) => {
        handleStatusChange(status);
        handleClose();
    };
    return (
        <div>
            {loading ? <Loader /> : <div>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>User Name</TableCell>
                                <TableCell>Picture</TableCell>

                                <TableCell>Status</TableCell>
                                <TableCell> </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users?.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.name}</TableCell>
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell  >
                                        <Zoom>
                                            {user.photoUrl && <img src={user.photoUrl} width={30} height={30} alt="User Photo" style={{ cursor: 'zoom-in', borderRadius: '50%', marginTop: '10px' }} />}
                                        </Zoom>
                                    </TableCell>
                                    <TableCell className={getStatusClass(user.status)}>
                                        {user.status.charAt(0).toUpperCase() + user.status.slice(1)}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e, user)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="pagination">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        color='primary'
                    />
                </div>
                <Dialog BackdropProps={{ style: { backgroundColor: 'rgba(128, 128, 128, 0.5)' } }} open={isOpenMenu} onClose={handleClosePopup}>
                    <DialogTitle>Change Pet Status</DialogTitle>


                    <MenuItem className={classes.barMenuItem} onClick={() => handleStatusMenuItemClick('active')}>Active</MenuItem>
                    <MenuItem className={classes.barMenuItem} onClick={() => handleStatusMenuItemClick('reported')}>Reported</MenuItem>
                    <MenuItem className={classes.barMenuItem} onClick={() => handleStatusMenuItemClick('disabled')}>Disabled</MenuItem>

                    <MenuItem className={classes.barMenuItem} onClick={() => handleStatusMenuItemClick('deleted')}>Deleted</MenuItem>
                </Dialog>
            </div>}
        </div>
    );
};
const useStyles = makeStyles({ "name": { Pets } })(theme => ({
    formInput: {
        ...theme.typography.customInput,
        marginBottom: '10px !important'
    },
    disabled: {
        color: theme.palette.error.main, // Customize the color for disabled status
    },
    active: {
        color: theme.palette.primary.main, // Customize the color for active status
    },
    deleted: {
        color: theme.palette.warning.main, // Customize the color for deleted status
    },
    reported: {
        color: theme.palette.warning.main, // Customize the color for deleted status
    },
    avatar: {
        margin: 'auto',

        width: '100px!important',
        height: '100px!important'

    },
    dialogHeader: {
        paddingBottom: '50px!important'
    }, barMenuItem: {
        width: '200px',
        textAlign: 'center',
        padding: '20px!important',
        "&:hover": {
            background: theme.palette.success.main
        }
    },

}));


export default Pets
