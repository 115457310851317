import { Box } from '@mui/material';
import React from 'react';
import Pets from '../../../components/Views/pets/pets';

const PetsPage = () => {
    return (
        <Box m={6}>
            <Pets />
        </Box>
    )
}

export default PetsPage;