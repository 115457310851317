import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import Layout from './components/Layout/Layout'
import "@fontsource/maven-pro"
import { ThemeProvider } from '@mui/material/styles';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import themes from './themes';
import { HelmetProvider } from 'react-helmet-async';
import { firebaseConfigProd } from './config/firebase-prod'
import { firebaseConfigDev } from './config/firebase-dev'

const customization = {
    borderRadius: 10,
    fontFamily: "'Roboto', sans-serif",
    locale: "en",
    mode: "light",
};
 
const app = initializeApp(process.env.REACT_APP_NODE_ENV_VAR === "PROD" ? firebaseConfigProd : firebaseConfigDev);
const auth = getAuth(app);
if (auth) {

    auth.setPersistence('local');

}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <HelmetProvider>
            <BrowserRouter >


                <ThemeProvider theme={themes(customization)} >

                    <Layout>

                        <App />

                    </Layout>

                </ThemeProvider>

            </BrowserRouter>
        </HelmetProvider>
    </Provider >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
